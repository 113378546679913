<template>
  <Alert :isActive="isError" v-if="isError">{{errorMessage}}</Alert>
  <Loading :isShow="isLoading" v-if="!isError">
  <Navigation></Navigation>
  <div class="theme-container" :isShow="!isLoading">
    <PageTitle
      :title="pageTitle"
      :imgSrc="pageIcon"
    ></PageTitle>
  </div>
  <div class="section-container">
    <router-link to="/special-events" class = "nav-back">
        <img style="margin-right: 20px" src='@/assets/images/previous-icon.svg' />
        BACK TO ALL EVENTS
    </router-link>
    
    <div class="container" v-if="events[clickedEventKey]">
        <div class="item">
            <h3 class = "subtitle">{{ events[clickedEventKey].subtitle}}</h3>
            <h1>{{events[clickedEventKey].title}}</h1>
            <div style="font-size: 26px" v-html="events[clickedEventKey].titleDetails"></div>
            <h3 class = "date">{{ events[clickedEventKey].date }}</h3>
        </div>
        <div class="item">
            <img :src="events[clickedEventKey].imgSrc" alt="Slalom White Logo" class = "title-image"/>
        </div>
        <div class="break"> </div>
        <template v-for="contentSection in events[clickedEventKey].contentSection">
          <div class="item-full">
            <template v-if="contentSection.description">
              <p v-html="contentSection.description"></p>
            </template>
          </div>
          <div class="item-full">
            <p style="font-weight: 600" v-if="contentSection.listTitle"> {{contentSection.listTitle}} </p>
            <ul v-for="text in contentSection.listContent">
              <li v-html="text"></li>
            </ul>
          </div>
        </template>
        <div class="item-full-center" v-if="events[clickedEventKey].optionalImgSrc">
          <img :src="events[clickedEventKey].optionalImgSrc" alt="Slalom White Logo" class="optional-image"/>
        </div>
    </div>
  <!--
    <div class= "carousel-container">
        <router-link :to="`/special-events/all-events/${parseInt(clickedEventKey) > 0 ? clickedEventKey - 1 : eventCount - 1}`" class="controller-link">
        <img src='@/assets/images/previous-icon.svg' class="carousel-arrow"/>
            {{parseInt(clickedEventKey) + 1}}
        </router-link>
        of
        <router-link :to="`/special-events/all-events/${(parseInt(clickedEventKey) + 1) % eventCount}`" class="controller-link">
            {{eventCount}}
            <img src='@/assets/images/previous-icon.svg' style="transform: scaleX(-1);" class="carousel-arrow" />
        </router-link>
    </div>
  -->
  </div>
  </Loading>
</template>

<script>

import Navigation from "../components/Navigation.vue";
import PageTitle from "../components/PageTitle.vue";
import api from "@/services/api.js";
import Loading from '@/components/Loading.vue';
import Alert from '@/components/Alert.vue';

export default {
    props: ["clickedEventKey"],
    data: () => ({
      clickedEventKey: 0,
      isLoading: false,
      isError: false,
      errorMessage: '',
      events: [ {
          subtitle: "",
          title: "",
          titleDetails: "",
          date: "",
          description: "",
          imgSrc: ``,
          }
          ],
      eventCount: 5,
    }),
  computed: {
    specialEventsPageId() {
      const specialEventsPage = this.$store.state.pages.specialevents;
      if(specialEventsPage){
        return specialEventsPage.id;
      }
      return null;
    },
  },
	methods: {
		async getData() {
      const dateOptions = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' }
			this.isError = false;
			this.isLoading = true;
			try {
				const { data } = await api.getAllEvents(this.specialEventsPageId);
        this.pageTitle = data.pageTitle;
        this.pageIcon = data.pageIcon;
        this.events = data.events.eventList.map((evnt) => ({
          subtitle: evnt.event.subtitle,
          title: evnt.event.title,
          titleDetails: evnt.event.titleDetails.text,
          date: (new Date(evnt.event.date)).toLocaleDateString('en-US', dateOptions),
          contentSection: (evnt.event.contentSection && evnt.event.contentSection.length > 0) ? evnt.event.contentSection.map((cSection) => ({
            description: cSection.content? cSection.content.text : null,
            listTitle: cSection.listTitle,
            listContent: cSection.listContent && cSection.listContent.length > 0 && cSection.listContent[0]? cSection.listContent.map((content) => content ? content.text: "") : []
          })) : [],
          imgSrc: evnt.event.image,
          optionalImgSrc: (evnt.event.optionalImage && evnt.event.optionalImage.length > 0) ? evnt.event.optionalImage[0].imageFile : null,
        }))
			} catch (err) {
				this.isError = true;
				this.errorMessage = err.message;
			} finally {
				this.isLoading = false;
			}
		}
	},
  watch:{ 
    specialEventsPageId(newValue, oldValue) {
      if (newValue) {
        this.getData()
      }
    }
  },
	async beforeRouteUpdate(to, from, next) {
		//await this.getData();
		next();
	},

	async created() {
    if(this.specialEventsPageId){
		  await this.getData();
    }	
  },

  components: {
    PageTitle,
    Navigation,
    Loading,
    Alert,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";


.theme-container {
  @include relative;
  :deep(.page-title) {
    .title-container{
      background-color: $primary-color-3;
    }
  }
}

.nav-back {
  color: $primary-color-3;
  text-decoration: none;
  display: flex;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 50px;
  @include responsive(phone) {
    margin-left: 25px;
  }
}

.title-image {
    max-width:100%;
    margin-top: -25%;
    margin-left: 50px;
    @include responsive(phone) {
        @include margin(0);
        min-width: 0;
        min-height: 0;
        max-width: 100%;
    }
    @include responsive(tablet) {
        @include margin(0);
        min-width: 0;
        min-height: 0;
        max-width: 100%;
    }
}

h1 {
  color: $primary-color-3;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.section-container {
  background-color: $secondary-color-4;
  @include padding(10 10 10 10);
  @include margin(0 0 8 0);
  @include responsive(phone) {
    @include padding(4 0 4 0);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
  z-index: 1;
}

.container {
  background-color: $secondary-color-9;
  @include padding(8);
  border-bottom-right-radius: 4em;
  display: flex;
  flex-wrap: wrap;
  @include responsive(phone) {
    @include padding(4 2 4 2);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}

.item {
  flex-basis: 50%;
  align-items: flex-start;
    @include responsive(phone) {
        flex-basis: 100%;
    }
    @include responsive(tablet) {
        flex-basis: 100%;
    }
}

.item-full {
  flex-basis: 100%;
  align-items: flex-start;
}

.item-full-center {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.optional-image {
   margin-top: 50px;
   max-width:100%;
}

.arrow {
  margin-left: 10px;
}

.date {
  color: $secondary-color-8;
  font-size: 18px;
  margin-bottom: 10px;

}

.subtitle {
  color: $primary-color-3;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 10px;

}

.carousel-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 5px;
  font-weight: 600;
  margin-top: 5%;
  color: $secondary-color-10;
}

.carousel-arrow {
  @include padding(0 2 0 2);
}

.controller-link {
  color: $secondary-color-7;
  display: flex;
  text-align: center;
  text-decoration: none; 
  color: inherit;
}

</style>
